/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeftIcon, CloseIcon } from '~/assets/icons';
import ClipIcon from '~/assets/icons/ClipIcon';
import PaperPlaneIcon from '~/assets/icons/PaperPlaneIcon';
import propTypes from 'prop-types';
import useChat from './UseChat';
import { formatDateLabel } from '~/utils/helpers';
import { sendChat } from '~/services/homepage';
import { CounterContext } from '~/config/providers/ContextProviders';
import CautionFillIcon from '~/assets/icons/CautionFill';
import ChatBox from './ChatBox';

function ChatRoom({ onBack, onClose }) {
    const { state: {credential} } = React.useContext(CounterContext);
    const [PreloadMessage, setPreloadMessage] = useState([]);

    const [tempComment, setTempComment] = useState({
        comment: '',
        stateComment: ''
    });
    const [tempReply, setTempReply] = useState({
        reply: null,
        stateReply: null
    });
    const [tempImage, setTempImage] = useState({
        image: null,
        stateImage: null
    });

    const [startChat, setStartChat] = useState(true);
    const [loadingSending, setLoadingSending] = useState(false);
    const [limit, setLimit] = useState(20);

    function removeListener() {
        setTempComment((state) => ({
            ...state,
            comment: '',
            stateComment: ''
        }));
        setTempImage({
            image: null,
            stateImage: null
        });
        setTempReply({
            reply: null,
            stateReply: null
        });
    }

    const { chatLog } = useChat(limit, setPreloadMessage, credential.data);

    const userInfo = {
        id: credential.id,
        username: credential.username.split('/')[0],
        roomNo: credential.room_no,
        user_type: credential.user_type,
        sender: credential.username
    };

    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current && startChat && chatLog.length !== 0) {
            chatContainerRef.current.scrollTop = chatContainerRef.current?.scrollHeight;
            setStartChat(false);
        }
    }, [startChat, chatLog, loadingSending]);

    const formatDateTime = (dateString) => {
        const options = { hour: '2-digit', minute: '2-digit' };
        const date = new Date(dateString);
        
        return date.toLocaleTimeString([], options);
    };

    const handleChange = (e) => {
        const targetValue = e.target.value;

        setTempComment((state) => ({
            ...state,
            comment: targetValue,
            stateComment: targetValue
        }));
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            setTempImage({
                image: file,
                stateImage: file
            });
        }
    };

    const handleReply = (message) => {
        setTempReply({
            reply: {
                comment: message.comment,
                id: message.id,
                dir_image: message.dir_image || null,
                sender: message.sender.split('/')[0],
                created_date: message.created_date
            },
            stateReply: {
                comment: message.comment,
                id: message.id,
                dir_image: message.dir_image || null,
                sender: message.sender.split('/')[0],
                created_date: message.created_date
            }
        });
    };

    const sendText = async () => {
        setLoadingSending(true);
        setStartChat(true);

        setTempComment((state) => ({
            ...state,
            comment: ''
        }));
        setTempImage((state) => ({
            ...state,
            image: null
        }));
        setTempReply((state) => ({
            ...state,
            reply: null
        }));

        // setLimit((state) => state+1);
        
        if (chatContainerRef.current && chatLog.length !== 0) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }

        const formData = new FormData();
        
        // formData.append('transaction_id', userInfo.id);
        formData.append('comment', tempComment.comment);

        if(tempImage.stateImage){
            formData.append('dir_image', tempImage.stateImage);
        }
        
        if(tempReply.stateReply){
            formData.append('reply_id', tempReply.stateReply.id);
            formData.append('reply_type', 'chat');    
        }

        const response = await sendChat(formData);

        if(response && response.data.status == 1){
            let timeout = tempImage.stateImage || tempComment.stateComment ? 2000 : 1000;

            setTimeout(() => {
                if (chatContainerRef.current && chatLog.length !== 0) {
                    chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight;
                }
    
                removeListener();
                setLoadingSending(false);
            }, timeout);
        } else {
            removeListener();
            setLoadingSending(false);
        }
    };
    
    // const handleKeyDown = (e) => {
    //     if (e.key === 'Enter' && !e.shiftKey && text !== '') {
    //         e.preventDefault();
    //         sendText();
    //     }
    // };

    return (
        <div className={`flex flex-col h-[600px] max-sm:h-[88vh]`}>
            <div className='flex justify-between items-center gap-[12px] w-full py-[20px] px-[24px]'>
                <div className='flex items-center gap-[12px]'>
                    <div className='cursor-pointer' onClick={onBack}>
                        <ChevronLeftIcon />
                    </div>
                    <div className='rounded-full w-[40px] h-[40px] bg-black' />

                    <div className='flex flex-col gap-[6px]'>
                        <p className='__text-body-1 __ff-lato'>Nuswapada</p>
                        <p className='__text-caption __ff-lato'>Online</p>
                    </div>
                </div>

                <div className='cursor-pointer' onClick={onClose}>
                    <CloseIcon />
                </div>
            </div>

            { tempImage.image ? 
                (
                    <div className={`w-full h-full flex justify-center bg-colors-gray50`} style={{ position: 'relative', overflow: 'hidden' }}>
                        <img
                            src={URL.createObjectURL(tempImage.image)}
                            alt={"Uploaded"}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                padding: '24px'
                            }}/>
                        <button
                            onClick={() => {
                                setTempImage({
                                    image: null,
                                    stateImage: null
                                });
                            }}
                            style={{
                                position: 'absolute',
                                top: '12px',
                                right: '12px',
                                zIndex: '1'
                            }}>
                            <CloseIcon />
                        </button>
                    </div>
                )   :
                <div ref={chatContainerRef} className='flex flex-1 py-[20px] px-[24px] flex-col overflow-y-auto'>
                    {chatLog.map((log, index) => {
                        return (
                            <div key={index} className='flex flex-col gap-y-4'>
                                <div className='__text-caption __ff-lato justify-center' style={{ display: 'flex' }}>
                                    <p className='p-[8px] px-[16px] m-[12px]' style={{ backgroundColor: 'rgb(240 241 245 / var(--tw-bg-opacity))', borderRadius: '10px' }}>
                                        {formatDateLabel(log.date)}
                                    </p>
                                </div>

                                {log.message.map((message, idx) => {
                                    const senderName = message.sender.includes('/') ? message.sender.split('/')[0] : message.sender;
                                    const isCurrentUser = userInfo.sender === senderName;
                                    const styleWrapperChat = isCurrentUser ? 'border-colors-gold400 right-[-8px] rotate-90' : 'border-colors-gray100 left-[-8px] -rotate-90';
                                    const actionType = message.type === 'request' || message.type === 'request';

                                    return (
                                        <div key={idx} className={`h-fit min-w-[30%] ${actionType ? 'w-full' : 'max-w-[75%]'} w-fit ${isCurrentUser ? 'self-end' : 'self-start'}`}>
                                            {
                                                message.type === 'request' ? (
                                                    <div className='w-full flex justify-center items-center'>
                                                        <div className='flex items-center p-3 gap-x-1 bg-colors-gray50 rounded'>
                                                            <CautionFillIcon />
                                                            <span className='text-colors-blue400'>{message.comment}</span>
                                                        </div>
                                                    </div>
                                                ) : message.type === 'task' ? (
                                                    <div className='w-full flex justify-center items-center'>
                                                        <div className='flex items-center p-3 gap-x-1 bg-colors-gray50 rounded'>
                                                            <span className='text-colors-blue400'>{message.comment}</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <ChatBox message={message} messageReply={message.reply} dateString={formatDateTime(message?.created_date)} 
                                                        handleReply={handleReply} 
                                                        styleWrapperChat={styleWrapperChat} isCurrentUser={isCurrentUser} senderName={senderName} 
                                                        idx={idx} log={log} />
                                                )
                                            }

                                            {/* {log.message[log.message.length - 1].id === message.id && loadingSending ? (
                                                // <div className={`pt-4 h-fit min-w-[30%] max-w-[75%] w-fit self-end`}>
                                                <div className='pt-5'>
                                                    <ChatBox message={{
                                                        comment: commentUser,
                                                        dir_image: images,
                                                    }} 
                                                    messageReply={reply} 
                                                    dateString={'Sending...'}
                                                    handleReply={handleReply} 
                                                    styleWrapperChat='border-colors-gold400 right-[-8px] rotate-90' 
                                                    isCurrentUser={true} 
                                                    senderName={userInfo.sender.includes('/') ? userInfo.sender.split('/')[0] : userInfo.sender}
                                                    sending={true} />
                                                </div>
                                            ) : null}  */}
                                        </div>
                                    );
                                })}
                                
                            </div>
                        );
                    })}

                    {loadingSending ? (
                        <div className={`pt-4 h-fit min-w-[30%] max-w-[75%] w-fit self-end`}>
                            {/* <div className='pt-5'> */}
                            <ChatBox message={{
                                comment: tempComment.stateComment,
                                dir_image: tempImage.stateImage,
                            }} 
                            messageReply={tempReply.stateReply} 
                            dateString={'Sending...'}
                            handleReply={handleReply} 
                            styleWrapperChat='border-colors-gold400 right-[-8px] rotate-90' 
                            isCurrentUser={true} 
                            senderName={userInfo.sender.includes('/') ? userInfo.sender.split('/')[0] : userInfo.sender}
                            sending={true} />
                        </div>
                    ) : null} 
                </div>

            }

            <div>
                {tempReply.reply && (
                    <div className='p-[8px]'>
                        <div className='bg-colors-gray50 p-[8px] flex justify-between gap-4'>
                            <div className='pl-[12px]'>
                                <p className='__text-caption __ff-lato text-colors-gray200'>{tempReply.reply?.sender}</p>
                                <div className='flex justify-between items-center'>
                                    <p className='__text-title1 __ff-lato'>{tempReply.reply?.comment}</p>
                                    {tempReply.reply.dir_image !== null && (
                                        <img
                                            src={process.env.REACT_APP_API_URL + tempReply.reply.dir_image}
                                            alt={"Uploaded"}
                                            style={{
                                                width: '25%',
                                                height: 'auto',
                                                objectFit: 'cover',
                                            }}/>
                                    )}
                                </div>
                            </div>
                            <div className='cursor-pointer' onClick={() => {
                                setTempReply({
                                    reply: null,
                                    stateReply: null
                                });
                            }}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className='flex gap-[12px] border-t border-gray[100] px-[24px]'>
                    <textarea 
                        disabled={loadingSending}
                        rows='2'
                        type='text' 
                        value={tempComment.comment}
                        // onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        className='__text-body-2 __ff-lato flex-1 outline-none resize-none pt-[18px]' 
                        placeholder='Type new message here...'/>
                        
                    <div className='py-[18px] px-[6px]'>
                        <label htmlFor='uploadFile' className='cursor-pointer'>
                            <ClipIcon />
                        </label>

                        <input 
                            disabled={loadingSending}
                            type='file' 
                            id='uploadFile' 
                            accept='image/png, image/jpeg'
                            onChange={handleFileUpload} 
                            hidden={true} />
                    </div>

                    <div className='py-[18px] px-[6px]'>
                        <button disabled={loadingSending} onClick={sendText}>
                            <PaperPlaneIcon />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatRoom;

ChatRoom.propTypes = {
    onBack: propTypes.func,
    onClose: propTypes.func
};
