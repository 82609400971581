import React, { useEffect, useState, useRef } from 'react';
import Modal from '~/components/Modal';
import ReactSlider from 'react-slider';
import InfoIcon from '~/assets/icons/InfoIcon';
import { formatRupiahOnInput, formatToPrice, unformatNumber } from '~/utils/helpers';
import propTypes from 'prop-types';
import QtyCounter from '~/components/QtyCounter';
import { loadService } from '~/services/villa';

const MIN = 500000;
const MAX = 4500000;

function SearchModalFilter({ visibility, onClose, modalTitle, onApply }) {
    const formInput = useRef();
    const [listService, setListService] = useState([]);
    const [value, setValue] = useState([MIN, MAX]);
    const [filter, setFilter] = useState({
        num_bedrooms: 0,
        num_bathrooms: 0,
        recommendation: [],
        service: []
    });

    useEffect(() => {
        getListService();
    }, []);

    const handleApply = () => {
        // pass your filter value here
        onApply({
            ...filter,
            price_min: value[0],
            price_max: value[1],
        });

        onClose();
    };

    const handleReset = () => {
        formInput.current.reset();

        setValue([MIN, MAX]);
        setFilter({
            num_bedrooms: 0,
            num_bathrooms: 0,
            recommendation: [],
            service: []
        });
    };

    const getListService = async() => {
        const response = await loadService();

        if (response) {
            setListService(response.data.data);
        }
    };

    const handleReccomendation = (e) => {
        if (e.target.checked) {
            setFilter({
                ...filter,
                recommendation: [...filter.recommendation, e.target.value]
            });
        } else {
            setFilter({
                ...filter,
                recommendation: filter.recommendation.filter((val) => val === e.target.value)
            });
        }
    };

    const handleService = (e) => {
        if (e.target.checked) {
            setFilter({
                ...filter,
                service: [...filter.service, e.target.value]
            });
        } else {
            setFilter({
                ...filter,
                service: filter.service.filter((val) => val === e.target.value)
            });
        }
    };

    const renderModalFilter = () => {
        return (
            <div className='flex flex-col py-[20px]'>
                <form ref={formInput} className='flex flex-col px-[20px] pt-[10px] gap-[20px] max-h-[500px] overflow-y-auto'>
                    <div className='flex items-center gap-[8px]'>
                        <input 
                            type='checkbox' 
                            className='w-[20px] h-[20px] appearance-none' 
                            name='type' 
                            value='group'
                            onChange={handleReccomendation}
                            id='6' />
                        <label className='__text-body-1 __ff-lato' htmlFor='6'>Perfect for Group</label>
                    </div>
                    <div className='flex items-center gap-[8px]'>
                        <input 
                            type='checkbox' 
                            className='w-[20px] h-[20px] appearance-none' 
                            name='type' 
                            value='couple'
                            onChange={handleReccomendation}
                            id='7' />
                        <label className='__text-body-1 __ff-lato' htmlFor='7'>Perfect for Couple</label>
                    </div>
                    <div className='flex items-center gap-[8px]'>
                        <input 
                            type='checkbox' 
                            className='w-[20px] h-[20px] appearance-none' 
                            name='type' 
                            value='family'
                            onChange={handleReccomendation}
                            id='8' />
                        <label className='__text-body-1 __ff-lato' htmlFor='8'>Perfect for Family</label>
                    </div>

                    <hr />

                    <div className='flex justify-between items-center'>
                        <label className='__text-body-1 __ff-lato'>Bedrooms</label>

                        <QtyCounter
                            defaultValue={filter.num_bedrooms}
                            onChange={(val) => setFilter({ ...filter, num_bedrooms: val })} />
                    </div>

                    <div className='flex justify-between items-center'>
                        <label className='__text-body-1 __ff-lato'>Bathrooms</label>

                        <QtyCounter
                            defaultValue={filter.num_bathrooms}
                            onChange={(val) => setFilter({ ...filter, num_bathrooms: val })} />
                    </div>

                    <hr />
                    <label className='__text-body-1 __ff-lato __fw-600'>Prices per nights</label>
                    <ReactSlider
                        min={MIN}
                        max={MAX}
                        step={50000}
                        value={[unformatNumber(value[0]), unformatNumber(value[1])]}
                        className='w-[100%] h-[4px] bg-colors-blue400 relative __custom-range-slider'
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        onChange={setValue} />

                    <div className='flex justify-between'>
                        <span className='__text-caption text-colors-gray200'>IDR {formatToPrice(value[0].toString())}</span>
                        <span className='__text-caption text-colors-gray200'>IDR {formatToPrice(value[1].toString())}</span>
                    </div>

                    <div className='flex gap-[8px] flex-col'>
                        <label className='__text-body-1 __ff-lato __fw-600 flex gap-[2px]'>Minimum Price <InfoIcon /></label>
                        <div className='flex p-[12px] gap-[8px] border border-colors-gray100'>
                            <label className='__text-body-1 __ff-lato __fw-500 text-colors-gray200'>IDR</label>
                            <input 
                                type='text' 
                                value={formatToPrice(value[0])}
                                onChange={(e) => setValue([e.target.value, value[1]])}
                                onInput={(e) => formatRupiahOnInput(unformatNumber(e.target.value))} 
                                className='flex-1 outline-none' />
                        </div>
                    </div>

                    <div className='flex gap-[8px] flex-col'>
                        <label className='__text-body-1 __ff-lato __fw-600 flex gap-[2px]'>Maximum Price <InfoIcon /></label>
                        <div className='flex p-[12px] gap-[8px] border border-colors-gray100'>
                            <label className='__text-body-1 __ff-lato __fw-500 text-colors-gray200'>IDR</label>
                            <input 
                                type='text' 
                                value={formatToPrice(value[1])} 
                                onChange={(e) => setValue([value[0], e.target.value])}
                                onInput={(e) => formatRupiahOnInput(unformatNumber(e.target.value))} 
                                className='flex-1 outline-none' />
                        </div>
                    </div>

                    <hr />

                    <label className='__text-body-1 __ff-lato __fw-600'>Service</label>
                    <div className='flex'>
                        <div className='flex flex-1 flex-col gap-[18px]'>
                            {
                                listService.map((val, index) => index < listService.length / 2 && (
                                    <div className='flex items-center gap-[8px]' key={index}>
                                        <input 
                                            type='checkbox' 
                                            className='w-[20px] h-[20px] appearance-none' 
                                            name='type' 
                                            onChange={handleService}
                                            value={val.code}
                                            id={val.code} />
                                        <label className='__text-body-1 __ff-lato' htmlFor={val.code}>{val.name}</label>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='flex flex-1 flex-col gap-[18px]'>
                            {
                                listService.map((val, index) => index >= listService.length / 2 && (
                                    <div className='flex items-center gap-[8px]' key={index}>
                                        <input 
                                            type='checkbox' 
                                            className='w-[20px] h-[20px] appearance-none' 
                                            name='type' 
                                            onChange={handleService}
                                            value={val.code}
                                            id={val.code} />
                                        <label className='__text-body-1 __ff-lato' htmlFor={val.code}>{val.name}</label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </form>

                <div className='bottom-[20px] flex gap-[12px] pt-[24px] px-[20px]'>
                    <button onClick={handleReset} className='flex-1 py-3 bg-white border-2 border-inherit mdText font-medium text-black'>Clear</button>
                    <button onClick={handleApply} className='flex-1 py-3 bg-colors-blue400 mdText font-medium text-white'>Apply Filter</button>
                </div>
            </div>
        );
    };
        
    return (
        <Modal
            visibility={visibility}
            modalTitle={modalTitle}
            onClose={onClose}
            renderElement={renderModalFilter()} />
    );
}

export default SearchModalFilter;

SearchModalFilter.propTypes = {
    onApply: propTypes.func,
    modalTitle: propTypes.string,
    visibility: propTypes.bool,
    onClose: propTypes.func,
};