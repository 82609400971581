import React, { useState, useEffect } from 'react';
import { MinusIcon, PlusIcon } from '~/assets/icons';
import propTypes from 'prop-types';

function QtyCounter({ min = 0, max = 999999, defaultValue = 0, onChange }) {
    const [qty, setQty] = useState(defaultValue);

    const handleAddition = () => {
        if (defaultValue === max) return;

        setQty(defaultValue + 1);
    };

    const handleDeduction = () => {
        if (defaultValue === min) return;

        setQty(defaultValue - 1);
    };

    const disabledDeduction = () => {
        if (defaultValue === min) {
            return '#D4D7E2';
        }

        else return '#D6AD60';
    };

    useEffect(() => {
        if (qty !== defaultValue) {
            onChange(qty);
        }
    }, [qty]);

    return (
        <div className='flex gap-[16px] items-center'>
            <MinusIcon
                onClick={handleDeduction}
                stroke={disabledDeduction()}/>

            <span>{defaultValue}</span>

            <PlusIcon onClick={handleAddition} />
        </div>
    );
}

export default QtyCounter;

QtyCounter.propTypes = {
    min: propTypes.number,
    max: propTypes.number,
    defaultValue: propTypes.number,
    onChange: propTypes.func
};
