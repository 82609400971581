/* eslint-disable no-unused-vars */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import { ArrowRightIcon, BuildingIcon, CalendarIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, MapPinIcon, UserIcon } from '~/assets/icons';
import Card from '~/components/Card';
import { formatNewDate, formatToPrice } from '~/utils/helpers';
import usePagination from '../../utils/usePagination';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Modal from '~/components/Modal';
import SearchModalFilter from './SearchModalFilter';
import SortIcon from '~/assets/icons/SortIcon';

import 'react-datepicker/dist/react-datepicker.css';

// tom tom integrated Maps
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import tt from '@tomtom-international/web-sdk-maps';

// range date picker
// import { DateRangePicker } from 'react-dates';
import moment from 'moment';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import QtyCounter from '~/components/QtyCounter';
import { loadProvince, loadRegencies, loadVillaList } from '~/services/villa';
import Select, { components } from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '~/config/routes/Routes';
import BuildingFillIcon from '~/assets/icons/BuildingFillIcon';
import Loader from '~/components/Loader';
import DatePicker from 'react-datepicker';
import propTypes from 'prop-types';
import useWindowSize from '~/utils/useWindowSize';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownIcon />
        </components.DropdownIndicator>
    );
};

const ExampleCustomInput = forwardRef(
    ({ value, className, onClick, placeholder='Select date' }, ref) => (
        <button className={`${className} w-full max-sm:py-1`} onClick={onClick} ref={ref} 
            style={{
                textAlign: 'left'
            }}>
            {value ? value : placeholder}
        </button>
    ),
);

ExampleCustomInput.displayName = 'ExampleCustomInput';

ExampleCustomInput.propTypes = {
    value: propTypes.string,
    className: propTypes.string,
    onClick: propTypes.func,
    placeholder: propTypes.string
};

function SearchResult() {
    const {state} = useLocation();
    const mapElement = useRef();
    const sortForm = useRef();
    const navigate = useNavigate();

    const datePickerRef = useRef(null);
    const [focusedInput, setFocusedInput] = useState(null);

    const [dateModal, setDateModal] = useState(false);
    const [Loading, setLoading] = useState(true);
    const [sort, setSort] = useState({
        // Both are DESC / ASC
        SortByName: '',
        SortByPrice: ''
    });

    const [filter, setFilter] = useState({
        province: state?.province || '',
        city: state?.regency || '',
        check_in: formatNewDate(state?.checkin) || '',
        check_out: formatNewDate(state?.checkout) || '',
        num_bedrooms: state?.guest || '',
        num_bathrooms: "",
        price_min: "",
        price_max: "",
        service: [],
        recommendation: [],
        SortByName: '',
        SortByPrice: ''
    });

    const [ModalFilter, setModalFilter] = useState(false);
    const [ModalSort, setModalSort] = useState(false);
    const [ListVilla, setListVilla] = useState([]);
    const [listProvince, setListProvince] = useState([]);
    const [listRegencies, setListRegencies] = useState([]);
    const { PageList, PageNumber, ApplyPagination, onNext, onPrevious } = usePagination(ListVilla);

    const [startDate, setStartDate] = useState(state?.checkin || null);
    const [endDate, setEndDate] = useState(state?.checkout || null);

    const screenSize = useWindowSize();

    useEffect(() => {
        getAllData();
    }, [filter]);

    useEffect(() => {
        async function loadInitialCity() {
            if(state?.regency) {
                const response = await loadProvince();

                if(response) {
                    const ProvinceId = response.find((val) => val.name === state.province).id;
                    const newResponse = await loadRegencies(ProvinceId);

                    if (newResponse) {
                        setListRegencies(newResponse);
                    }
                }
            }
        }

        loadInitialCity();
    }, []);    

    useEffect(() => {
        if (ListVilla.length !== 0 && !Loading) {
            const renderMap = tt.map({
                key: "mV7NBhsJmmuyFJEPdFCuYOArqNDh29Gg",
                container: mapElement.current,
                center: ListVilla[0].geolocation.split(',').reverse(),
                zoom: 13
            });
    
            ListVilla.map((val) => {
                let address = formatToPrice(parseInt(val.price));
                let location = val.geolocation.split(',').reverse();
    
                var markerElement = document.createElement('div');
    
                markerElement.className = 'marker';
    
                var markerContentElement = document.createElement('div');
    
                markerContentElement.className = 'marker-content-text';
                markerContentElement.style.backgroundColor = '#fff';
                markerContentElement.style.borderColor = '#24336B';
                markerElement.appendChild(markerContentElement);
    
                var textElement = document.createElement('p');
    
                textElement.className = `text-sm max-sm:text-xs text-colors-red400 font-semibold`;
                textElement.innerText = address;
                markerContentElement.appendChild(textElement);
    
                new tt.Marker({
                    element: markerElement
                })
                    .setLngLat(location)
                    .setPopup(new tt.Popup({ 
                        closeButton: false,
                        offset: 75 / 2,
                        anchor: 'bottom',
                    }).setHTML(address))
                    .addTo(renderMap);
            });
        }
    }, [ListVilla, Loading]);

    function convertDate(dateString) {
        const parts = new Date(dateString).toLocaleString().split(',')[0].split('/');

        const year = parts[2];
        const month = parts[0] < 10 ? '0' + parts[0] : parts[0];
        const day = parts[1];
      
        return year + '-' + month + '-' + day;
    }

    const getAllData = async () => {
        setLoading(true);

        if (listProvince.length !== 0) {
            const response = await loadVillaList({
                ...filter,
                check_in: convertDate(filter.check_in),
                check_out: convertDate(filter.check_out),
            });

            if (response) {
                setListVilla(response.data.data);

                setLoading(false);
            }
        } else {
            const allPromise = Promise.all([
                loadVillaList(filter),
                loadProvince()
            ]);
    
            const promises = await allPromise;
            
            if (promises) {
                setListVilla(promises[0].data.data);
                setListProvince(promises[1]);
                
                setLoading(false);
            }
        }
    };

    const handleProvince = (event) => {
        setFilter({
            ...filter,
            province: event.value
        });

        getRegencies(event.value);
    };

    const handleRegencies = (event) => {
        setFilter({
            ...filter,
            city: event.value
        });
    };

    const handleDate = ({ startDate, endDate }) => {
        setFilter({
            ...filter,
            check_in: startDate,
            check_out: endDate,
        });
    };

    const applyFilter = (val) => {
        setFilter({
            ...filter,
            ...val
        });
    };

    const handleSort = (e) => {
        if (e.target.value === 'recommended') {
            setSort({
                ...sort,
                SortByName: '',
                SortByPrice: ''
            });
        } else {
            // currentIndex[0] => index || currentIndex[1] => value
            const currentIndex = e.target.value.split('_');
            const unselectIndex = Object.keys(sort).find((index) => index !== currentIndex[0]);

            setSort({
                ...sort,
                [unselectIndex]: '',
                [currentIndex[0]]: currentIndex[1]
            });
        }
    };

    const applySort = (e) => {
        e.preventDefault();

        setFilter({
            ...filter,
            ...sort
        });

        setModalSort(false);
    };

    const clearSort = () => {
        setModalSort(false);

        sortForm.current.reset();

        setFilter({
            ...filter,
            SortByName: '',
            SortByPrice: ''
        });
    };

    const getRegencies = async (currentValue) => {
        const ProvinceId = listProvince.find((val) => val.name.toLowerCase() === currentValue.toLowerCase()).id;

        const response = await loadRegencies(ProvinceId);

        if (response) {
            setListRegencies(response);
        }
    };

    const toggleDateRange = () => {
        setDateModal(!dateModal);
    };

    const renderLocation = (district, province) => {
        return (
            <div className='flex gap-[7px] items-center'>
                <MapPinIcon />
                <span className='__text-body-2 text-colors-gray200'>{district}, {province}</span>
            </div>
        );
    };

    const onChangeDate = (dates) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);

        setFilter((state) => ({
            ...state,
            check_in: formatNewDate(start),
            check_out: formatNewDate(end)
        }));
    };

    const renderModalSort = () => {
        return (
            <form ref={sortForm} onSubmit={applySort} className='flex flex-col p-[20px] pt-[10px] gap-[16px]'>
                <div className='flex items-center gap-[8px]'>
                    <input 
                        type='radio' 
                        className='w-[20px] h-[20px] appearance-none' 
                        value='recommended' 
                        name='sort'
                        onChange={handleSort}
                        id='1' />
                    <label className='__text-body-1 __ff-lato' htmlFor='1'>Recommended</label>
                </div>
                <div className='flex items-center gap-[8px]'>
                    <input 
                        type='radio' 
                        className='w-[20px] h-[20px] appearance-none' 
                        name='sort'
                        value='SortByPrice_ASC'
                        onChange={handleSort}
                        id='2' />
                    <label className='__text-body-1 __ff-lato'  htmlFor='2'>Price (Low to High)</label>
                </div>
                <div className='flex items-center gap-[8px]'>
                    <input 
                        type='radio' 
                        className='w-[20px] h-[20px] appearance-none' 
                        name='sort'
                        value='SortByPrice_DESC'
                        onChange={handleSort}
                        id='3' />
                    <label className='__text-body-1 __ff-lato' htmlFor='3'>Price (High to Low)</label>
                </div>
                <div className='flex items-center gap-[8px]'>
                    <input 
                        type='radio' 
                        className='w-[20px] h-[20px] appearance-none' 
                        name='sort'
                        value='SortByName_ASC'
                        onChange={handleSort}
                        id='4' />
                    <label className='__text-body-1 __ff-lato' htmlFor='4'>City Name (A to Z)</label>
                </div>
                <div className='flex items-center gap-[8px]'>
                    <input 
                        type='radio' 
                        className='w-[20px] h-[20px] appearance-none' 
                        name='sort'
                        value='SortByName_DESC'
                        onChange={handleSort}
                        id='5' />
                    <label className='__text-body-1 __ff-lato' htmlFor='5'>City Name (Z to A)</label>
                </div>

                <div className='flex gap-[12px] pt-[24px]'>
                    <button onClick={clearSort} className='flex-1 py-3 bg-white border-2 border-inherit mdText font-medium text-black'>Clear</button>
                    <button type='submit' className='flex-1 py-3 bg-colors-blue400 mdText font-medium text-white'>Apply</button>
                </div>
            </form>
        );
    };

    const renderDayContents = (day) => {
        const dayText = day.format('D');
        const isToday = day.isSame(moment(), 'day');
        const isPastDay = day.isBefore(moment(), 'day');
      
        let content = dayText;
        let extraClass = '';
      
        if (isToday) {
            content = <div className='relative'>
                <span id='date-today' className='absolute -top-3 left-0 right-0 text-[10px]'>Today</span>
                <span className='text-colors-gray300'>{content}</span>
            </div>;
            extraClass = 'today';
        } else if (isPastDay) {
            content = <s className='text-colors-gray200'>{dayText}</s>;
            extraClass = 'past-day';
        }
      
        return (
            <div className={`custom-day ${extraClass}`}>
                {content}
            </div>
        );
    };

    const capitalizeText = (text) => {
        text = text.toLowerCase();

        const firstLetter = text.charAt(0);
        const firstLetterCap = firstLetter.toUpperCase();
        const remainingLetters = text.slice(1);
        
        return firstLetterCap + remainingLetters;
    };

    const optionProvince = listProvince.map((val) => ({ label: val.name.toLowerCase(), value: val.name }));
    const optionRegencies = listRegencies.map((val) => ({ label: val.name.toLowerCase(), value: val.name }));

    return (
        <div className='bg-white'>
            <div style={{ borderBottom: `1px solid rgba(212, 215, 226, 1)` }}>
                <Header scrollOpacity={0.41} colorLogo={true} isLogin={false} />
            </div>

            <h3 className='__text-body-2 __ff-lato mt-10 mb-10 mx-auto container max-lg:hidden'>
                <span className='text-colors-gold500'>Homepage</span> / {capitalizeText(filter.province) || 'All'}
            </h3>

            <div className='container max-lg:w-full max-lg:p-[16px] mx-auto flex flex-row items-center flex-wrap bg-white pb-[20px] max-lg:pb-[0px] max-lg:flex-col max-lg:items-start'>
                <div className='flex flex-1 flex-row flex-wrap max-lg:flex-col max-lg:w-[100%] pb-[20px] border-b border-colors-gray100 max-lg:border-0'>
                    <div className='flex flex-1 border-r border-colors-gray100 mr-5 max-lg:border-b max-lg:border-r-0 max-lg:py-[10px] max-lg:mr-0'>
                        <div className='flex flex-1 flex-row items-center gap-x-3'>
                            <MapPinIcon />

                            <Select
                                className='basic-single'
                                isSearchable={true}
                                value={optionProvince.find(val => val.value === filter.province)}
                                onChange={handleProvince}
                                styles={{ 
                                    container: (base) => ({
                                        ...base,
                                        width: '100%'
                                    }),
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        border: 'none',
                                        boxShadow: 'none',
                                        marginLeft: -8,
                                        textTransform: 'capitalize',
                                        cursor: 'pointer'
                                    }),
                                    option: (base) => ({
                                        ...base,
                                        textTransform: 'capitalize'
                                    })
                                }}
                                components={{ 
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null
                                }}
                                placeholder='All Province'
                                options={optionProvince} />
                        </div>
                    </div>
                    <div className='flex flex-1 border-r border-colors-gray100 mr-5 max-lg:border-b max-lg:border-r-0 max-lg:py-[10px] max-lg:mr-0'>
                        <div className='flex flex-1 flex-row items-center gap-x-3'>
                            <BuildingIcon />

                            <Select
                                className='basic-single'
                                isSearchable={true}
                                value={optionRegencies.find(val => val.value === filter.city)}
                                onChange={handleRegencies}
                                isDisabled={!filter.province}
                                styles={{ 
                                    container: (base) => ({
                                        ...base,
                                        width: '100%'
                                    }),
                                    control: (baseStyles, { isDisabled }) => ({
                                        ...baseStyles,
                                        border: 'none',
                                        boxShadow: 'none',
                                        marginLeft: -8,
                                        textTransform: 'capitalize',
                                        backgroundColor: isDisabled ? '#fff' : '',
                                        cursor: isDisabled ? 'none' : 'pointer'
                                    }),
                                    option: (base) => ({
                                        ...base,
                                        textTransform: 'capitalize'
                                    })
                                }}
                                components={{ 
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null
                                }}
                                placeholder='All Cities'
                                options={optionRegencies} />
                        </div>
                    </div>
                    
                    {/* ======== Date Range Overlay ========= */}
                    { 
                        dateModal && 
                            <div className={`fixed top-0 left-0 w-[100%] h-[100vh] z-10`} onClick={toggleDateRange} /> 
                    }

                    {/* ======== Date Range Component ========= */}
                    <div className='flex flex-1 border-r border-colors-gray100 mr-5 max-lg:border-b max-lg:border-r-0 relative max-sm:py-[4px] max-lg:py-[10px] max-lg:mr-0'>
                        <div className='flex flex-1 flex-row gap-x-3 lg:ml-0 items-center xl:gap-x-3 w-full lg:border-r border-colors-gray100  max-lg:border-r-0 max-lg:py-[10px]'>
                            <CalendarIcon />
                            <DatePicker
                                // selected={startDate}
                                onChange={onChangeDate}
                                minDate={new Date()}
                                dateFormat='dd/MM/yyyy'
                                placeholderText='Select date'
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange={true}
                                withPortal={screenSize.width <= 768 ? true : false}
                                disabledKeyboardNavigation={true}
                                customInput={<ExampleCustomInput className='example-custom-input' />}
                                showDisabledMonthNavigation={true} />
                                    
                        </div>
                        {/* <div className='flex flex-row items-center '>
                            <CalendarIcon />
                            <div className='date-range-picker-container max-lg:!px-0'>
                                <DateRangePicker
                                    ref={datePickerRef}
                                    startDate={filter.check_in}
                                    endDate={filter.check_out}
                                    customArrowIcon={<ArrowRightIcon />}
                                    startDateId='checkin'
                                    endDateId='checkout'
                                    numberOfMonths={window.innerWidth >= 760 ? 2 : 1}
                                    onDatesChange={handleDate}
                                    daySize={50}
                                    startDatePlaceholderText='Check-in'
                                    endDatePlaceholderText='Check-out'
                                    displayFormat='DD/MM/YYYY'
                                    navNext={<ChevronRightIcon />}
                                    navPrev={<ChevronLeftIcon />}
                                    focusedInput={focusedInput}
                                    renderDayContents={renderDayContents}
                                    hideKeyboardShortcutsPanel={true}
                                    transitionDuration={0}
                                    onFocusChange={focusedInput => setFocusedInput(focusedInput)} />
                            </div>
                        </div> */}
                    </div>
                    <div className='flex flex-1 mr-5 max-lg:pt-[20px] max-lg:pb-[40px] max-lg:mr-0'>
                        <div className='flex flex-1 flex-row items-center justify-between'>
                            <div className='flex gap-x-3'>
                                <UserIcon />
                                <p className='mdText text-colors-gray300'>Guest</p>
                            </div>

                            <QtyCounter 
                                defaultValue={filter.num_bedrooms === '' ? 0 : filter.num_bedrooms}
                                onChange={(qty) => setFilter({ ...filter, num_bedrooms: qty })} />
                        </div>
                    </div>
                </div>
                
                <div className='pb-[16px] border-b border-colors-gray100 max-lg:border-0 max-lg:w-[100%]'> {/* add border bottom  */}
                    <button onClick={() => setModalFilter(true)} className='px-[54px] py-3 bg-colors-blue400 mdText font-bold text-white max-lg:w-[100%] max-lg:mt-[20px]'>Filter</button>
                </div>
            </div>

            <hr className='mt-[20px] mx-auto w-[calc(100%-)] hidden max-lg:block' />

            <div className='mx-auto container mt-[80px] max-lg:p-[16px] min-h-[400px]'>
                { Loading && <Loader /> }

                {
                    Loading ? null : ListVilla.length === 0
                        ? <div className='flex flex-col content-between items-center mb-[152px]'>
                            <BuildingFillIcon />
                            <p className='__text-title-1 __ff-neuton text-colors-gray300 mt-[20px]'>No Result Found</p>
                            <p className='__text-body-1 __ff-lato text-colors-gray200 mt-[8px] text-center'>Try changing or removing some of your filters.</p>
                        </div>

                        : <div className='flex flex-row max-lg:flex-col max-lg:gap-[20px]'>
                            <div className='flex flex-col flex-1 mr-[48px] max-lg:mr-0'>
                                <div className='flex flex-1 justify-between items-start mb-[24px]'>
                                    <h3 className='__text-body-1 __ff-lato text-colors-gray200'>{ListVilla.length} Result for {capitalizeText(filter.province) || 'Nearest Villa'}</h3>
                                    <button onClick={() => setModalSort(true)} className='px-5 py-2 __text-body-2 __ff-lato flex gap-[8px] border border-colors-blue400 text-colors-blue400'><SortIcon />Sort by</button>
                                </div>
                                <div className='grid grid-cols-2 max-sm:grid-cols-1 grid-flow-row gap-x-[24px] gap-y-[32px]'>
                                    {
                                        ApplyPagination(ListVilla).map((val, index) => {
                                            return(
                                                <div className='' key={index}>
                                                    <Card
                                                        ImageSource={process.env.REACT_APP_API_URL + val.first_image}
                                                        Title={val.name}
                                                        onClick={() => navigate(ROUTES.VillaDetail + val.id)}
                                                        Subtitle={renderLocation(val.district, val.province)}
                                                        Facilities={[`${val.num_bedrooms} Bedrooms`, `${val.num_bathrooms} Bathrooms`]}
                                                        Price={formatToPrice(parseInt(val.price).toString())}/>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div className='flex self-center mt-[56px] gap-[8px]'>
                                    <div className='w-[40px] h-[40px] flex items-center justify-center cursor-default' onClick={onPrevious}>
                                        <ChevronLeftIcon />
                                    </div>
                                    {
                                        PageList.map((val, index) => {
                                            return (
                                                <div className={`__text-body-2 __ff-lato w-[40px] h-[40px] flex items-center justify-center ${val === PageNumber ? 'bg-colors-blue400 text-colors-white' : ''}`} key={index}>
                                                    {val}
                                                </div>
                                            );
                                        })
                                    }
                                    <div className='w-[40px] h-[40px] flex items-center justify-center cursor-default' onClick={onNext}>
                                        <ChevronRightIcon />
                                    </div>
                                </div>
                            </div>
                            
                            <div className='basis-1/3 max-lg:hidden'>
                                <div className='bg-black h-[500px] flex items-center justify-center' ref={mapElement} />
                            </div>
                        </div>
                }
            </div>

            <div className='h-[100px]' />

            <Footer isQuestion={false} />

            <Modal
                visibility={ModalSort}
                modalTitle='Sort by'
                onClose={() => setModalSort(false)}
                renderElement={renderModalSort()} />

            <SearchModalFilter
                visibility={ModalFilter}
                modalTitle='Filter'
                onClose={() => setModalFilter(false)}
                onApply={(val) => applyFilter(val)} />

            {/* <TawkMessengerReact
                propertyId='property_id'
                widgetId='default'/> */}
        </div>
    );
}

export default SearchResult;

// SearchResult.propTypes = {
//     state: propTypes.object
// };