export function formatToPrice(number = 0) {
    const numericValue = number.toString().replace(/\D/g, '');

    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const formatRupiahOnInput = (value) => {
    const numericValue = value.toString().replace(/\D/g, '');

    value = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export function unformatNumber(formattedNumber = 0) {
    if (formattedNumber === 0) return 0;

    return parseInt(formattedNumber.toString().replace(/[.,]/g, ''));
}

export const baseSize = 1536;
/**
 *
 * @param {String} dateString
 * @returns Date Formatted String
 */

export function useFullDate(dateString) {
    if(dateString === '0000-00-00') {
        return '-';
    }

    const date = new Date(dateString);

    // Define arrays for the day and month names
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    // Get the day, date, month, and short year
    const dateNum = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString();
    
    // Create the formatted date string
    const formattedDate = dateNum < 10 ? '0' + dateNum : dateNum + ' ' + month + ' ' + year;

    return formattedDate;
}

/**
 *
 * @param {String} date
 * @returns Date Formatted String
 */
export function formatDateLabel(date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const inputDateTime = inputDate.getTime();
    const currentDateTime = currentDate.getTime();
    const millisecondsInDay = 24 * 60 * 60 * 1000;
  
    if (
        inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear()
    ) {
        return 'Today';
    }
  
    if (
        inputDate.getDate() === currentDate.getDate() - 1 &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear()
    ) {
        return 'Yesterday';
    }
  
    if (currentDateTime - inputDateTime <= 7 * millisecondsInDay) {
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        return dayNames[inputDate.getDay()];
    }
  
    return useFullDate(inputDate);
}

export function formatDateArticle(dateString) {
    if (!dateString) {
        return '';
    }
    
    const dateParts = dateString.split(" ")[0].split("-"); 
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);

    const newDate = new Date(year, month - 1, day); 

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const monthName = monthNames[newDate.getMonth()];

    const formattedDate = `${newDate.getDate()} ${monthName} ${newDate.getFullYear()}`;

    return formattedDate;
}

export function formatDate(inputDate) {
    var dateObj = new Date(inputDate);

    var day = ("0" + dateObj.getDate()).slice(-2);
    var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    var year = dateObj.getFullYear();

    var hours = ("0" + dateObj.getHours()).slice(-2);
    var minutes = ("0" + dateObj.getMinutes()).slice(-2);

    var formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;

    return formattedDate;
}

export function convertToIDR(number = 0) {
    const amount = parseInt(number);
    const formatNumber = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
    }).format(amount);

    return formatNumber.replace('Rp', 'IDR');
}

export function formatDateReview(inputDate) {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const dateParts = inputDate.split(' ')[0].split('-');
    const year = dateParts[0];
    const monthIndex = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);
    
    return `${day} ${months[monthIndex]} ${year}`;
}

export function useDateTime(date) {
    const now = new Date(date);
  
    const year = now.getFullYear();
    const month = (now.getMonth() + 1 < 10 ? '0' : '') + (now.getMonth() + 1);
    const day = (now.getDate() < 10 ? '0' : '') + now.getDate();
    const hours = (now.getHours() < 10 ? '0' : '') + now.getHours();
    const minutes = (now.getMinutes() < 10 ? '0' : '') + now.getMinutes();
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function formatNewDate(date) {
    if(date || date !== null) {
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    } else return '';
}